


























import { Component, Prop, Vue } from 'vue-property-decorator';
import IntroOdekakePointPanel from '@/components/SignupComplete/Intro/IntroOdekakePointPanel.vue';
import IntroOneIdPanel from '@/components/SignupComplete/Intro/IntroOneIdPanel.vue';
import IntroOnePanel from '@/components/SignupComplete/Intro/IntroOnePanel.vue';
import WarnUnsyncedPointOnlyRealCardPanel from '@/components/SignupComplete/WarnCardType/WarnUnsyncedPointOnlyRealCardPanel.vue';
import WarnCreditCardPanel from '@/components/SignupComplete/WarnCardType/WarnCreditCardPanel.vue';

@Component({
  components: {
    IntroOdekakePointPanel,
    IntroOneIdPanel,
    IntroOnePanel,
    WarnUnsyncedPointOnlyRealCardPanel,
    WarnCreditCardPanel
  }
})
export default class EventPanel extends Vue {
  readonly EVENT_ID_KEY = 'event_id';

  @Prop()
  readonly warnCardTypeComponent?: string;

  moveToEventDetail() {
    const eventId = localStorage.event_id;
    localStorage.removeItem(this.EVENT_ID_KEY);
    this.$router.push({
      name: 'event-detail',
      params: {
        id: eventId
      }
    });
  }
}
