




















import { Component, PropSync, Vue } from 'vue-property-decorator';

@Component
export default class GenderForm extends Vue {
  @PropSync('genderTyp', { type: String, required: true })
  syncedGenderTyp!: string;
}
