


















































































































































































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import OnceButton from '@/components/OnceButton.vue';
import { UserSignupForm } from '@/models/forms/UserForm';

@Component({
  components: {
    OnceButton
  }
})
export default class SignupConfirm extends Vue {
  @Prop({ required: true })
  readonly form!: UserSignupForm;

  @Prop({ required: true })
  readonly digitalOpFlg!: boolean;

  @Prop({ required: true })
  readonly btnLoading!: boolean;

  @Prop({ required: true, type: Boolean })
  readonly isOpappAirCardExists!: boolean;

  @Prop({ required: true, type: Boolean })
  readonly isFromOpapp!: boolean;

  @Emit()
  next() {
    return;
  }

  @Emit()
  back() {
    return;
  }
}
