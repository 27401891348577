











































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class IntroOnePanel extends Vue {
  @Prop()
  readonly isDisplayTitle?: boolean;
}
