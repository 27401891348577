






















import IntroOdekakePointPanel from '@/components/SignupComplete/Intro/IntroOdekakePointPanel.vue';
import IntroOneIdPanel from '@/components/SignupComplete/Intro/IntroOneIdPanel.vue';
import IntroOnePanel from '@/components/SignupComplete/Intro/IntroOnePanel.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: { IntroOdekakePointPanel, IntroOneIdPanel, IntroOnePanel }
})
export default class PremiumCouponPanel extends Vue {
  @Prop() readonly warnCardTypeComponent?: string;
  @Prop() readonly redirectPath!: string;
}
