
















import { Component, Vue } from 'vue-property-decorator';
import IntroOpAppPanel from '@/components/SignupComplete/Intro/IntroOpAppPanel.vue';

@Component({
  components: {
    IntroOpAppPanel
  }
})
export default class WarnCreditCardPanel extends Vue {}
