











































import { Component, PropSync, Vue, Prop } from 'vue-property-decorator';
import filterUtils from '@/common/filterUtils';

@Component
export default class HiraNameForm extends Vue {
  @PropSync('hiraFirstName', { type: String, required: true })
  syncedHiraFirstName!: string;
  async formatHiraFirstName() {
    this.syncedHiraFirstName = filterUtils.funcs.formatKanaToHira(
      this.syncedHiraFirstName
    );
    await this.$nextTick();
    this.$validator.validate('hiraFirstName');
  }

  @PropSync('hiraLastName', { type: String, required: true })
  syncedHiraLastName!: string;
  async formatHiraLastName() {
    this.syncedHiraLastName = filterUtils.funcs.formatKanaToHira(
      this.syncedHiraLastName
    );
    await this.$nextTick();
    this.$validator.validate('hiraLastName');
  }

  @Prop({ type: Boolean })
  isEcopAuthenticated!: boolean;
}
