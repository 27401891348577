














































import { Component, Vue, Prop } from 'vue-property-decorator';
import IntroOneIdPanel from '@/components/SignupComplete/Intro/IntroOneIdPanel.vue';
import WarnUnsyncedPointOnlyRealCardPanel from '@/components/SignupComplete/WarnCardType/WarnUnsyncedPointOnlyRealCardPanel.vue';
import WarnCreditCardPanel from '@/components/SignupComplete/WarnCardType/WarnCreditCardPanel.vue';

@Component({
  components: {
    IntroOneIdPanel,
    WarnUnsyncedPointOnlyRealCardPanel,
    WarnCreditCardPanel
  }
})
export default class OpAppPanel extends Vue {
  @Prop({ required: true })
  readonly opappOs!: string;

  @Prop()
  readonly warnCardTypeComponent?: string;
}
