



































































import { Component, PropSync, Vue, Prop } from 'vue-property-decorator';
import utils from '@/common/utils';

@Component
export default class Phone1Form extends Vue {
  @PropSync('phone1', { type: String, required: true })
  syncedPhone1!: string;

  @PropSync('phone2', { type: String, required: true })
  syncedPhone2!: string;

  @PropSync('phone3', { type: String, required: true })
  syncedPhone3!: string;

  @Prop({ type: Boolean })
  isEcopAuthenticated!: boolean;

  errMsg = '';

  async validatePhone() {
    // 3エリア全て入力されているか必須チェック。次のチェックへ進む
    const phoneVal = [this.syncedPhone1, this.syncedPhone2, this.syncedPhone3];
    const isOkRequire = this.chkRequirePhoneVal(phoneVal);
    if (!isOkRequire) {
      this.errMsg = '';
      return false;
    }
    // VeeValidateをチェック。次のチェックへ進む
    const phoneForm = ['phone1_1', 'phone1_2', 'phone1_3'];
    const isOkVeeValidate = await this.execVeeValidate(phoneForm);
    if (!isOkVeeValidate) {
      this.errMsg = '';
      return false;
    }
    // 合計桁数をチェック。満たされてない場合は、合計桁数が違う旨のメッセージを表示
    const isOkSumDigits = this.validatePhoneSumDigits(phoneVal);
    if (!isOkSumDigits) {
      this.errMsg = utils.getSumDigitsMsg(['電話番号①']);
      return false;
    }
    this.errMsg = '';
    return true;
  }

  chkRequirePhoneVal(phoneVal: string[]) {
    return phoneVal.every((v: string) => !!v);
  }

  async execVeeValidate(phoneForm: string[]) {
    const res = await Promise.all(
      phoneForm.map(phoneEl => {
        return this.$validator.validate(phoneEl);
      })
    );
    return res.every(v => v);
  }

  validatePhoneSumDigits(phoneVal: string[]) {
    return utils.validateSumDigits(phoneVal, 10, 11);
  }
}
