
























































import { Component, PropSync, Vue } from 'vue-property-decorator';
import utils from '@/common/utils';

@Component
export default class Phone2Form extends Vue {
  @PropSync('phone1', { type: String, required: true })
  syncedPhone1!: string;

  @PropSync('phone2', { type: String, required: true })
  syncedPhone2!: string;

  @PropSync('phone3', { type: String, required: true })
  syncedPhone3!: string;

  errMsg = '';

  async validatePhone2() {
    // 電話番号②は必須ではないため、3つのフォームのいずれかに値が入力されている場合のみvalidationを実行する
    const phoneVal = [this.syncedPhone1, this.syncedPhone2, this.syncedPhone3];
    if (!phoneVal.some(i => !!i)) {
      this.errMsg = '';
      return true;
    }

    // 3エリア全て入力されているか必須チェック。次のチェックへ進む
    const phoneForm = ['phone2_1', 'phone2_2', 'phone2_3'];
    const isOkRequire = this.chkRequirePhoneVal(phoneVal);
    if (!isOkRequire) {
      this.errMsg = utils.allInputRequiredMsg('電話番号②');
      return false;
    }

    // VeeValidateをチェック。次のチェックへ進む
    const isOkVeeValidate = await this.execVeeValidate(phoneForm);
    if (!isOkVeeValidate) {
      this.errMsg = '';
      return false;
    }

    const isOkSumDigits = this.validatePhoneSumDigits(phoneVal);
    if (!isOkSumDigits) {
      this.errMsg = utils.getSumDigitsMsg(['電話番号②']);
      return false;
    }
    this.errMsg = '';
    return true;
  }

  async execVeeValidate(phoneForm: string[]) {
    const res = await Promise.all(
      phoneForm.map(phoneEl => {
        return this.$validator.validate(phoneEl);
      })
    );
    return res.every(v => v);
  }

  validatePhoneSumDigits(phoneVal: string[]) {
    return utils.validateSumDigits(phoneVal, 10, 11);
  }

  chkRequirePhoneVal(phoneVal: string[]) {
    return phoneVal.every((v: string) => !!v);
  }
}
