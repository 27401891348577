

















import { Component, PropSync, Vue } from 'vue-property-decorator';

@Component
export default class JobForm extends Vue {
  @PropSync('shokugyouTyp', { type: String, required: true })
  syncedShokugyouTyp!: string;
}
