

















import { Component, PropSync, Vue } from 'vue-property-decorator';
import filterUtils from '@/common/filterUtils';

@Component
export default class CityForm extends Vue {
  @PropSync('city', { type: String, required: true })
  syncedCity!: string;
  async formatCity() {
    this.syncedCity = filterUtils.funcs.formatHalfWidthToFullWidth(
      this.syncedCity
    );
    await this.$nextTick();
    this.$validator.validate();
  }
}
