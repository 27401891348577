






























import { Component, PropSync, Vue, Prop } from 'vue-property-decorator';

@Component
export default class OpSecurityCodeRegisterForm extends Vue {
  @PropSync('securityCode', { required: true })
  syncedSecurityCode!: string;

  @Prop({ type: Boolean })
  isPointOnlyRealCard!: boolean;

  @Prop({ type: Boolean })
  isEcopAuthenticated!: boolean;

  errorMsg = '';
  error = false;
}
