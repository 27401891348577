





























import IntroOdekakePointPanel from '@/components/SignupComplete/Intro/IntroOdekakePointPanel.vue';
import IntroOneIdPanel from '@/components/SignupComplete/Intro/IntroOneIdPanel.vue';
import IntroOnePanel from '@/components/SignupComplete/Intro/IntroOnePanel.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { IntroOdekakePointPanel, IntroOneIdPanel, IntroOnePanel }
})
export default class OpExchangePanel extends Vue {
  @Prop() itemId!: string;
  @Prop() itemName!: string;
}
