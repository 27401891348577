























import { Component, PropSync, Vue } from 'vue-property-decorator';

@Component
export default class PromoEmailFlgForm extends Vue {
  @PropSync('promoMailFromOneReceiveFlg', { type: Boolean, required: true })
  syncedPromoMailFromOneReceiveFlg!: boolean;

  @PropSync('promoMailFromOpCardReceiveFlg', { type: Boolean, required: true })
  syncedPromoMailFromOpCardReceiveFlg!: boolean;
}
