














































































































import { Component, Vue } from 'vue-property-decorator';
import OpAppPanel from '@/components/SignupComplete/OpAppPanel.vue';
import CampaignPanel from '@/components/SignupComplete/CampaignPanel.vue';
import OpGiftPanel from '@/components/SignupComplete/OpGiftPanel.vue';
import SubscriptionPanel from '@/components/SignupComplete/SubscriptionPanel.vue';
import PremiumCouponPanel from '@/components/SignupComplete/PremiumCouponPanel.vue';
import EventPanel from '@/components/SignupComplete/EventPanel.vue';
import OpExchangePanel from '@/components/SignupComplete/OpExchangePanel.vue';
import DefaultPanel from '@/components/SignupComplete/DefaultPanel.vue';

import { PREMIUM_COUPON_REDIRECT_PATH_KEY } from '@/pages/P0231.vue';

@Component({
  components: {
    OpAppPanel,
    CampaignPanel,
    OpGiftPanel,
    SubscriptionPanel,
    PremiumCouponPanel,
    EventPanel,
    OpExchangePanel,
    DefaultPanel
  }
})
export default class P0104 extends Vue {
  // OPカード紐付け周りの定数
  readonly UNSYNCED_POINT_ONLY_REAL_CARD = 'unsynced-point-only-real';
  readonly WARN_UNSYNCED_POINT_ONLY_REAL_CARD_PANEL =
    'WarnUnsyncedPointOnlyRealCardPanel';
  readonly CREDIT_CARD = 'credit';
  readonly WARN_CREDIT_CARD_PANEL = 'WarnCreditCardPanel';

  // 画面遷移先の制御で利用するlocalStorageのkey定数
  readonly SUBSCRIPTION_KEY = 'subscription_package_id';
  readonly EVENT_DETAIL_KEY = 'event_detail';
  readonly CAMPAIGN_ID_KEY = 'campaignId';
  readonly CAMPAIGN_NAME_KEY = 'campaignName';
  readonly OP_GIFT_KEY = 'register_op_gift';
  readonly OP_GIFT_AUTO_USE_KEY = 'auto_use';
  readonly OP_EXCHANGE_ITEM_ID_KEY = 'op_exchange_item_id';
  readonly OP_EXCHANGE_ITEM_NAME_KEY = 'op_exchange_item_name';

  isRegisteredUserViaSubscription = false;
  isRegisteredUserViaPremiumCoupon = false;
  isRegisteredUserViaEventDetail = false;
  isUserRegisteredViaCampaign = false;
  isUserRegisteredViaOpGift = false;
  isUserRegisteredViaOpExchange = false;
  isUserRegisteredDefault = false;

  redirectPath = '';
  campaignName = '';
  campaignId = '';
  opappOs = '00';
  warnCardTypeComponent = '';
  opExchangeItemId = '';
  opExchangeItemName = '';
  subscriptionPackageId = '';

  created() {
    this.initialize();
  }

  initialize() {
    this.setWarnCardTypeComponent();
    this.setTransitionPanel();
  }

  // 登録完了をトラッキングするためにデータレイヤーにプッシュ
  mounted() {
    this.$dataLayer.push({
      event: 'form_completion'
    });
  }

  get query() {
    return this.$route.query || {};
  }

  /**
   * カード紐付けに関してメッセージを表示する必要がある場合、各案内パネルに渡すコンポーネントを設定
   */
  setWarnCardTypeComponent() {
    if (this.query.cardType) {
      switch (this.query.cardType as string) {
        case this.UNSYNCED_POINT_ONLY_REAL_CARD:
          this.warnCardTypeComponent = this.WARN_UNSYNCED_POINT_ONLY_REAL_CARD_PANEL;
          break;
        case this.CREDIT_CARD:
          this.warnCardTypeComponent = this.WARN_CREDIT_CARD_PANEL;
          break;
      }
    }
  }

  /**
   * ユーザのステータスに応じて、画面遷移を案内するコンポーネントの表示フラグを設定
   */
  setTransitionPanel() {
    // 優先度1~6: localStorageに設定されている経由した画面情報で判定
    const isFlgFromLocalStorageSet = this.setFlgFromLocalStorage();
    // 特別な完了画面を表示する設定が行われた場合、localStorageにここでの判定にのみ用いる値があると判断し、後続でまとめて削除を行う。
    if (isFlgFromLocalStorageSet) {
      this.removeLocalStorage();
      return;
    }

    // 優先度7:OPアプリ経由 or モバイルPASMOチャージ経由 で登録
    if (this.query.opappOs && this.query.opappOs !== '00') {
      this.opappOs = this.query.opappOs as string;
      return;
    }

    // 優先度8:デフォルト表示
    this.isUserRegisteredDefault = true;
  }

  /**
   * localStorageに設定されている値から表示制御のフラグを設定する
   * @returns いずれかのフラグが設定された場合はtrue、どのフラグも設定されなかった場合falseを返す
   */
  // eslint-disable-next-line complexity
  setFlgFromLocalStorage() {
    // 優先度1: register-subscriptionを経由してアクセスした場合はisRegisteredUserViaSubscriptionをtrueにする。
    if (localStorage.subscription_package_id) {
      this.isRegisteredUserViaSubscription = true;
      this.subscriptionPackageId = localStorage.subscription_package_id;
      return true;
    }

    // 優先度2: premium-couponsを経由してアクセスした場合はisRegisteredUserViaPremiumCouponをtrueにする。
    if (PREMIUM_COUPON_REDIRECT_PATH_KEY in localStorage) {
      this.isRegisteredUserViaPremiumCoupon = true;
      this.redirectPath = localStorage[PREMIUM_COUPON_REDIRECT_PATH_KEY];
      return true;
    }

    // 優先度3:event-detailを経由してアクセスした場合はisRegisteredUserViaSubscriptionをtrueにする。
    if (localStorage.event_detail && localStorage.event_detail === 'true') {
      this.isRegisteredUserViaEventDetail = true;
      return true;
    }

    // 優先度4:campaignを経由してアクセスした場合はisUserRegisteredViaCampaignをtrueにする。
    if (localStorage.campaignId) {
      this.isUserRegisteredViaCampaign = true;
      this.campaignId = localStorage.campaignId;
      this.campaignName = localStorage.campaignName;
      return true;
    }

    // 優先度5:register-op-giftを経由してアクセスした場合はisUserRegisteredViaOpGiftをtrueにする。
    if (
      localStorage.register_op_gift &&
      localStorage.register_op_gift === 'true'
    ) {
      this.isUserRegisteredViaOpGift = true;
      return true;
    }

    // 優先度6: /odakyu-point/exchange-items/:id を経由してアクセスした場合
    if (
      localStorage.op_exchange_item_id &&
      localStorage.op_exchange_item_name
    ) {
      this.isUserRegisteredViaOpExchange = true;
      this.opExchangeItemId = localStorage.op_exchange_item_id;
      this.opExchangeItemName = localStorage.op_exchange_item_name;
      return true;
    }

    return false;
  }

  removeLocalStorage() {
    localStorage.removeItem(this.SUBSCRIPTION_KEY);
    localStorage.removeItem(this.EVENT_DETAIL_KEY);
    localStorage.removeItem(this.CAMPAIGN_ID_KEY);
    localStorage.removeItem(this.CAMPAIGN_NAME_KEY);
    localStorage.removeItem(this.OP_GIFT_KEY);
    localStorage.removeItem(this.OP_GIFT_AUTO_USE_KEY);
    localStorage.removeItem(this.OP_EXCHANGE_ITEM_ID_KEY);
    localStorage.removeItem(this.OP_EXCHANGE_ITEM_NAME_KEY);
    localStorage.removeItem(PREMIUM_COUPON_REDIRECT_PATH_KEY);
  }
}
