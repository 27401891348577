






















import { Component, PropSync, Vue } from 'vue-property-decorator';
import filterUtils from '@/common/filterUtils';

@Component
export default class TownForm extends Vue {
  @PropSync('town', { type: String, required: true })
  syncedTown!: string;
  async formatTown() {
    this.syncedTown = filterUtils.funcs.formatHalfWidthToFullWidth(
      this.syncedTown
    );
    await this.$nextTick();
    this.$validator.validate();
  }
}
