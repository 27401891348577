












































































































































































































































































































import {
  Component,
  Prop,
  PropSync,
  Ref,
  Vue,
  Watch
} from 'vue-property-decorator';
import NameForm from '@/components/profile/NameForm.vue';
import HiraNameForm from '@/components/profile/HiraNameForm.vue';
import GenderForm from '@/components/profile/GenderForm.vue';
import BirthForm from '@/components/profile/BirthForm.vue';
import JobForm from '@/components/profile/JobForm.vue';
import Phone1Form from '@/components/profile/Phone1Form.vue';
import Phone2Form from '@/components/profile/Phone2Form.vue';
import ZipCodeForm from '@/components/profile/ZipCodeForm.vue';
import PrefForm from '@/components/profile/PrefForm.vue';
import CityForm from '@/components/profile/CityForm.vue';
import TownForm from '@/components/profile/TownForm.vue';
import BuildingForm from '@/components/profile/BuildingForm.vue';
import OpRegisterForm from '@/components/OpRegisterForm/OpRegisterForm.vue';
import OpSecurityCodeRegisterForm from '@/components/OpRegisterForm/OpSecurityCodeRegisterForm.vue';
import PromoEmailFlgForm from '@/components/profile/PromoEmailForm.vue';
import $ from 'jquery';
import { UserSignupForm } from '@/models/forms/UserForm';
import utils from '@/common/utils.ts';
import { OpUtils } from '@/common/OpUtils';

@Component({
  components: {
    NameForm,
    HiraNameForm,
    GenderForm,
    BirthForm,
    JobForm,
    Phone1Form,
    Phone2Form,
    ZipCodeForm,
    PrefForm,
    CityForm,
    TownForm,
    BuildingForm,
    OpRegisterForm,
    OpSecurityCodeRegisterForm,
    PromoEmailFlgForm
  }
})
export default class SignupEdit extends Vue {
  @Ref() readonly NameForm!: NameForm;
  @Ref() readonly HiraNameForm!: HiraNameForm;
  @Ref() readonly GenderForm!: GenderForm;
  @Ref() readonly BirthForm!: BirthForm;
  @Ref() readonly JobForm!: JobForm;
  @Ref() readonly Phone1Form!: Phone1Form;
  @Ref() readonly Phone2Form!: Phone2Form;
  @Ref() readonly ZipCodeForm!: ZipCodeForm;
  @Ref() readonly PrefForm!: PrefForm;
  @Ref() readonly CityForm!: CityForm;
  @Ref() readonly TownForm!: TownForm;
  @Ref() readonly BuildingForm!: BuildingForm;
  @Ref() readonly OpRegisterForm!: OpRegisterForm;
  @Ref() readonly OpSecurityCodeRegisterForm!: OpSecurityCodeRegisterForm;
  @Ref() readonly PromoEmailFlgForm!: PromoEmailFlgForm;

  @PropSync('digitalOpFlg', { type: Boolean, required: true })
  syncedDigitalOpFlg!: boolean;

  @PropSync('isEcopAuthenticated', { type: Boolean, required: true })
  syncedIsEcopAuthenticated!: boolean;

  @Prop({ required: true })
  readonly form!: UserSignupForm;

  @Prop({ required: true, type: String })
  readonly resErrMsg!: string;

  @Prop({ required: true, type: Boolean })
  readonly isOpappAirCardExists!: boolean;

  @Prop({ required: true, type: Boolean })
  readonly isFromOpapp!: boolean;

  zipErrMsg = '';
  genderErrMsg = '';
  opRegisterErrMsg = '';
  isEmptyOpNumber = true; // OP番号が空文字であるか
  isCheckboxCautionTabVisible = false; // デジタルカード発行選択チェックボックス上に表示する注記タブが表示状態であるか

  @Watch('form.opCardNum1')
  @Watch('form.opCardNum2')
  @Watch('form.opCardNum3')
  watchIsEmptyOpNumber() {
    // OP番号が空文字であるか
    this.isEmptyOpNumber =
      !this.form.opCardNum1 && !this.form.opCardNum2 && !this.form.opCardNum3;
    // OP番号が空文字である場合には、注記タブを非表示とする
    if (this.isEmptyOpNumber) {
      this.isCheckboxCautionTabVisible = false;
    }
  }

  @Watch('digitalOpFlg')
  @Watch('form.genderTyp')
  @Watch('form.opCardNum1')
  @Watch('form.opCardNum2')
  @Watch('form.opCardNum3')
  // TODO:(t.nakamichi.c7@future.co.jp) 下記関数は呼び出しされていないので不要だと思われる
  validateOpRegisterForm() {
    if (
      !this.syncedDigitalOpFlg &&
      !(this.form.opCardNum1 && this.form.opCardNum2 && this.form.opCardNum3)
    ) {
      this.opRegisterErrMsg = this.$msg.get('2000022');
    } else {
      this.opRegisterErrMsg = '';
    }
  }

  async confirm() {
    if (await this.validateForm()) {
      this.$emit('next');
    } else {
      var custom = 0;
      const width = $(window).width() || 0;
      width >= 768 ? (custom = 250) : (custom = 300);
      $('.v-messages__message,.errFieldToScroll').each(function() {
        if ($(this).html() !== '') {
          const offset = $(this).offset() || { top: 0 };
          $('body, html').animate(
            {
              scrollTop: offset.top - custom
            },
            800
          );
          return false;
        }
      });
    }
  }

  async fetchAddress() {
    try {
      const res = await utils.fetchPostalCodeApi(
        this.form.zipcode1,
        this.form.zipcode2
      );
      this.zipErrMsg = '';
      this.form.pref = res.prefcode;
      this.form.city = res.ja.address1 + res.ja.address2;
      this.form.town = res.ja.address3;
      this.form.building = res.ja.address4;
    } catch (e) {
      this.zipErrMsg = this.$msg.get('2000005');
    }
  }

  async validateForm() {
    const result = Promise.all([
      this.NameForm.$validator.validateAll(),
      this.HiraNameForm.$validator.validateAll(),
      this.GenderForm.$validator.validateAll(),
      this.BirthForm.$validator.validateAll(),
      this.BirthForm.validateBirth(),
      this.JobForm.$validator.validateAll(),
      this.ZipCodeForm.$validator.validateAll(),
      this.Phone1Form.$validator.validateAll(),
      this.Phone1Form.validatePhone(),
      this.Phone2Form.$validator.validateAll(),
      this.Phone2Form.validatePhone2(),
      this.PrefForm.$validator.validateAll(),
      this.CityForm.$validator.validateAll(),
      this.TownForm.$validator.validateAll(),
      this.BuildingForm.$validator.validateAll(),
      this.OpRegisterForm.$validator.validateAll(),
      this.OpSecurityCodeRegisterForm.$validator.validateAll()
    ]);

    const isValidGenderForm = !(this.form.genderTyp == '');
    if (!isValidGenderForm) {
      this.genderErrMsg = this.$msg.get('2000023');
    }

    const isValidOpForm = !(
      this.isNotFulfilledOpNum && !this.syncedDigitalOpFlg
    );
    if (!isValidOpForm) {
      this.opRegisterErrMsg = this.$msg.get('2000022');
    }
    return (
      (await result).every(val => val) && isValidGenderForm && isValidOpForm
    );
  }

  get isNotFulfilledOpNum() {
    return !(
      this.form.opCardNum1 &&
      this.form.opCardNum2 &&
      this.form.opCardNum3
    );
  }

  // デジタルカード発行選択チェックボックスが非活性の際は、
  // pointer-eventsをnoneとすることでチェックボックスの親タブに設定したクリックアクションを有効にする
  get pointerEventsStatus(): 'auto' | 'none' {
    if (!this.isEmptyOpNumber || this.syncedIsEcopAuthenticated) {
      return 'none';
    }
    return 'auto';
  }

  setCheckboxCautionTabVisible() {
    // OPWEB移行ユーザでない、かつOP番号が1文字以上入力されている場合には、注記タブを表示
    if (!this.syncedIsEcopAuthenticated && !this.isEmptyOpNumber) {
      this.isCheckboxCautionTabVisible = true;
    }
  }

  onOpCardNumInput() {
    if (
      OpUtils.isPointOnlyRealCard(
        [this.form.opCardNum1, this.form.opCardNum2, this.form.opCardNum3].join(
          ''
        )
      )
    ) {
      return;
    }
    this.form.securityCode = '';
  }

  get isPointOnlyRealCard() {
    const opCardNo = [
      this.form.opCardNum1,
      this.form.opCardNum2,
      this.form.opCardNum3
    ].join('');
    return opCardNo.length === 12 && OpUtils.isPointOnlyRealCard(opCardNo);
  }
}
