





















import { Component, PropSync, Vue } from 'vue-property-decorator';

@Component
export default class PrefForm extends Vue {
  @PropSync('pref', { type: String, required: true })
  syncedPref!: string;

  get getPlaceHolder() {
    return this.syncedPref === this.$cls.PREF_CLS.OTHER.CD
      ? '住所未入力'
      : 'ーー';
  }

  get prefItem() {
    return this.$cls
      .createSelectItems('PREF_CLS')
      .filter((e: any) => e.value !== this.$cls.PREF_CLS.OTHER.CD);
  }

  // 住所未入力(99)の場合は確認画面に遷移させない
  validatePref() {
    return this.syncedPref !== this.$cls.PREF_CLS.OTHER.CD;
  }
}
