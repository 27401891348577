

















import { Component, PropSync, Vue } from 'vue-property-decorator';
import filterUtils from '@/common/filterUtils';

@Component
export default class BuildingForm extends Vue {
  @PropSync('building', { type: String, required: true })
  syncedBuilding!: string;
  async formatBuilding() {
    this.syncedBuilding = filterUtils.funcs.formatHalfWidthToFullWidth(
      this.syncedBuilding
    );
    await this.$nextTick();
    this.$validator.validate();
  }
}
