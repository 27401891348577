







































import { Component, PropSync, Vue, Prop, Watch } from 'vue-property-decorator';
import jis_x_0208 from '@/common/validators/jis_x_0208';

@Component
export default class NameForm extends Vue {
  @PropSync('lastName', { type: String, required: true })
  syncedLastName!: string;

  @PropSync('firstName', { type: String, required: true })
  syncedFirstName!: string;

  @Prop({ type: Boolean })
  isEcopAuthenticated!: boolean;

  mounted() {
    this.validateWhenEcopAuthenticated();
  }

  // isEcopAuthenticatedが`true`、かつ以下の値が`true`の場合のみ入力欄のdisabledが有効になる想定
  isLastNameValid: boolean = true;
  isFirstNameValid: boolean = true;

  // isEcopAuthenticatedが`true`の場合、バリデーション状態確認
  async validateWhenEcopAuthenticated() {
    if (this.isEcopAuthenticated) {
      await this.$validator.validate();
      await this.enableFormWhenValidationInvalid();
    }
  }

  // バリデーションが失敗している場合は入力欄のdisabledを解除
  enableFormWhenValidationInvalid() {
    this.isLastNameValid = !this.$validator.flags.lastName.invalid;
    this.isFirstNameValid = !this.$validator.flags.firstName.invalid;
  }

  // 文字列からJIS X 0208範囲外の文字を抽出し、リスト形式で返却する
  findNonJISX0208(str: string): string[] {
    return str.split('').filter(s => !jis_x_0208.validate(s));
  }

  // バリデーションエラーメッセージ変更
  @Watch('syncedLastName', { immediate: true })
  @Watch('syncedFirstName', { immediate: true })
  validatorLocalize() {
    const dict = {
      custom: {
        lastName: {
          jis_x_0208: `姓に使用できない文字（${this.findNonJISX0208(
            this.syncedLastName
          ).join(', ')}）が含まれています`
        },
        firstName: {
          jis_x_0208: `名に使用できない文字（${this.findNonJISX0208(
            this.syncedFirstName
          ).join(', ')}）が含まれています`
        }
      }
    };
    this.$validator.localize('ja', dict);
  }
}
