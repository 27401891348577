

































































import { Component, PropSync, Vue, Watch, Prop } from 'vue-property-decorator';
import utils from '@/common/utils';
import dayjs from 'dayjs';

@Component
export default class BirthForm extends Vue {
  @PropSync('birthYear', { type: String, required: true })
  syncedBirthYear!: string;

  @PropSync('birthMonth', { type: String, required: true })
  syncedBirthMonth!: string;

  @PropSync('birthDay', { type: String, required: true })
  syncedBirthDay!: string;

  @Prop({ type: Boolean })
  isEcopAuthenticated!: boolean;

  birthYearItems = utils.createNumberArray(
    Number.parseInt(dayjs().format('YYYY')) - 1900, // 1900年から現在の年までの選択肢を作成する
    1900
  );
  birthMonthItems = utils.createNumberArray(12);
  birthDayItems = utils.createNumberArray(31);
  birthErrMsg = '';

  @Watch('birthYear')
  @Watch('birthMonth')
  createBirthDayItems() {
    if (this.syncedBirthYear && this.syncedBirthMonth) {
      this.birthDayItems = utils.createNumberArray(
        utils.getLastDayOfMonth(
          Number.parseInt(this.syncedBirthYear),
          Number.parseInt(this.syncedBirthMonth)
        )
      );
    }
  }

  selectYear() {
    const yearForm = document.getElementsByName('birthYear')[0];
    if (!yearForm.classList.contains('selected_once')) {
      yearForm.classList.add('selected_once');
      this.syncedBirthYear = '1980';
    }
  }

  // 15歳未満の場合は確認画面に遷移させない
  validateBirth() {
    const today = Number(dayjs().format('YYYYMMDD'));
    const birthday = Number(
      this.syncedBirthYear +
        ('0' + this.syncedBirthMonth).slice(-2) +
        ('0' + this.syncedBirthDay).slice(-2)
    );
    if ((today - birthday) / 10000 >= 15) {
      this.birthErrMsg = '';
      return true;
    } else {
      this.birthErrMsg = this.$msg.get('2000031');
      return false;
    }
  }
}
