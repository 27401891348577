













































import { Component, Emit, PropSync, Vue } from 'vue-property-decorator';

@Component
export default class PostalCodeForm extends Vue {
  @PropSync('zipcode1', { type: String, required: true })
  syncedZipcode1!: string;

  @PropSync('zipcode2', { type: String, required: true })
  syncedZipcode2!: string;

  isDisable = true;

  isFilledZipCode() {
    if (this.syncedZipcode1 && this.syncedZipcode2) {
      Promise.all([
        this.$validator.validate('zipcode1'),
        this.$validator.validate('zipcode2')
      ]).then(val => {
        this.isDisable = !(val[0] && val[1]);
      });
    }
  }

  @Emit()
  fetchAddress() {
    return;
  }
}
