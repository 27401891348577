





























































































































































































import {
  Vue,
  Component,
  Watch,
  Emit,
  Prop,
  PropSync,
  Ref
} from 'vue-property-decorator';
import OnceButton from '@/components/OnceButton.vue';
import filterUtils from '@/common/filterUtils';
import { OpCardRepository } from '@/repositories/OpCardRepository';
import utils from '@/common/utils';
import dayjs from 'dayjs';
import { UserSignupForm } from '@/models/forms/UserForm';
import OpRegisterForm from '@/components/OpRegisterForm/OpRegisterForm.vue';

@Component({
  components: {
    OnceButton,
    OpRegisterForm
  }
})
export default class EcopAuth extends Vue {
  @Ref() readonly OpRegisterForm!: OpRegisterForm;

  @PropSync('btnLoading', { type: Boolean, required: true })
  syncedBtnLoading!: boolean;
  opCardNum1: string = '';
  opCardNum2: string = '';
  opCardNum3: string = '';
  hiraFirstName: string = '';
  hiraLastName: string = '';
  birthYear: string = '';
  birthMonth: string = '';
  birthDay: string = '';
  isGetUserInfoSuccess = false;
  error = false;
  errorMsg = '';

  @Prop({ required: true })
  readonly form!: UserSignupForm;

  @PropSync('isEcopAuthenticated', { type: Boolean, required: true })
  syncedIsEcopAuthenticated!: boolean;

  async formatHiraFirstName() {
    this.hiraFirstName = filterUtils.funcs.formatKanaToHira(this.hiraFirstName);
    await this.$nextTick();
    this.$validator.validate('hiraFirstName');
  }

  async formatHiraLastName() {
    this.hiraLastName = filterUtils.funcs.formatKanaToHira(this.hiraLastName);
    await this.$nextTick();
    this.$validator.validate('hiraLastName');
  }

  birthYearItems = utils.createNumberArray(
    Number.parseInt(dayjs().format('YYYY')) - 1900,
    1900
  );
  birthMonthItems = utils.createNumberArray(12);
  birthDayItems = utils.createNumberArray(31);
  birthErrMsg = '';

  @Watch('birthYear')
  @Watch('birthMonth')
  createBirthDayItems() {
    if (this.birthYear && this.birthMonth) {
      this.birthDayItems = utils.createNumberArray(
        utils.getLastDayOfMonth(
          Number.parseInt(this.birthYear),
          Number.parseInt(this.birthMonth)
        )
      );
    }
  }

  selectYear() {
    const yearForm = document.getElementsByName('birthYear')[0];
    if (!yearForm.classList.contains('selected_once')) {
      yearForm.classList.add('selected_once');
      this.birthYear = '1980';
    }
  }

  get opCardRepo() {
    return new OpCardRepository();
  }

  async validateForm() {
    const result = Promise.all([
      this.$validator.validateAll(),
      this.OpRegisterForm.$validator.validateAll()
    ]);

    return (await result).every(val => val);
  }

  getUserInfoForAutoInput() {
    this.errorMsg = '';

    this.validateForm().then(valid => {
      if (valid) {
        this.syncedBtnLoading = true;
        this.opCardRepo
          .getUserInfoForAutoInput(
            [this.opCardNum1, this.opCardNum2, this.opCardNum3].join(''),
            this.hiraLastName,
            this.hiraFirstName,
            this.birthYear,
            this.birthMonth,
            this.birthDay
          )
          .then(res => {
            this.isGetUserInfoSuccess = true;
            this.form.lastName = res.lastName;
            this.form.firstName = res.firstName;
            this.form.hiraLastName = res.hiraLastName;
            this.form.hiraFirstName = res.hiraFirstName;
            this.form.genderTyp = res.genderTyp;
            this.form.birthYear = res.birthYear;
            this.form.birthMonth = res.birthMonth;
            this.form.birthDay = res.birthDay;
            this.form.shokugyouTyp = res.shokugyouTyp;
            this.form.phone1_1 = res.phone1_1;
            this.form.phone1_2 = res.phone1_2;
            this.form.phone1_3 = res.phone1_3;
            this.form.zipcode1 = res.zipcode1;
            this.form.zipcode2 = res.zipcode2;
            this.form.pref = res.pref;
            this.form.city = res.city;
            this.form.town = res.town;
            this.form.building = res.building;
            this.form.opCardNum1 = res.odakyuCustomerNo.substring(0, 2);
            this.form.opCardNum2 = res.odakyuCustomerNo.substring(2, 9);
            this.form.opCardNum3 = res.odakyuCustomerNo.substring(9);
            this.syncedIsEcopAuthenticated = true;
            this.next();
          })
          .catch((errCode: number) => {
            this.error = true;
            this.handleGetUserInfoForAutoInputErr(errCode);
          })
          .finally(() => {
            this.syncedBtnLoading = false;
          });
      }
    });
  }

  handleGetUserInfoForAutoInputErr(errCode: number) {
    switch (errCode) {
      case 40000:
      case 40011:
      case 40101:
        this.errorMsg = this.$msg.get('2000012');
        break;
      case 40001:
        this.errorMsg = this.$msg.get('2000006');
        break;
      case 40006:
        this.errorMsg = this.$msg.get('2000210');
        break;
      default:
        this.errorMsg = this.$msg.get('2000011');
        break;
    }
  }

  @Emit()
  next() {
    return;
  }
}
